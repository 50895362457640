<template>
  <div class="add-new-product">
    <title-bar
      submitBtn
      submitBtnTitle="Add product"
      title="Create product"
      @submitPressed="submitPressed"
    />
    <product-form :productObject="product" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ProductForm from '@/views/Admin/Products/components/ProductForm.vue'

export default {
  components: {
    TitleBar,
    ProductForm
  },
  data() {
    return {
      action: null,
      product: {
        status: 'ACTIVE',
        category: '',
        url: '',
        name: '',
        position: 1,
        price: '',
        originalPrice: '',
        depositPrice: '',
        product: '',
        sku: '',
        file: null,
        variantSets: []
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  },
}
</script>
